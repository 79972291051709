import React, { useContext, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import { navigate } from "gatsby"
import { useAuth } from "@auth/useAuth"
import useFetch from "../../../hooks/useFetch"
import { isAfter, isBefore, isSameDay, isSameMonth } from "date-fns"

const tabs = [
  { label: "Day Passes", key: "dayPasses" },
  { label: "Offices", key: "offices" },
  { label: "Meeting Rooms", key: "meetingRooms" },
]

// setData([res?.dayPasses, res?.offices, res?.meetingRooms])
const PendingPayments = () => {
  const { makeRequest } = useAuth() || {}

  const { progress, data } = useFetch("pending-payments", "get")

  const [activeTab, setActiveTab] = useState(tabs?.[0])

  return (
    <>
      <SEO title="Pending Payments" />
      <Layout title={"Pending Payments"} progress={progress}>
        <Row>
          <Col lg="12">
            <ButtonGroup className={"mb-5"}>
              {tabs.map((tab) => (
                <Button
                  key={tab.key}
                  onClick={() => setActiveTab(tab)}
                  // outline={true}
                  color={activeTab.key === tab.key ? "primary" : "light"}
                >
                  {tab.label}
                </Button>
              ))}
            </ButtonGroup>

            <List bookings={data?.[activeTab?.key]} />
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default PendingPayments

const List = ({ bookings }) => {
  if (!bookings?.length) {
    return <p>No bookings with pending payments</p>
  }

  return (
    <Table bordered hover size="sm" striped>
      <thead>
        <tr>
          <th>Booking ID</th>
          <th>Created At</th>
          <th>Booking date</th>
          <th>Amount</th>
          <th>Location</th>
          <th>Source</th>
          <th>User</th>
          <th>Method</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map((booking) => {
          const bookingDate = new Date(booking.date || booking?.from)
          let due =
            !isSameDay(new Date(), bookingDate) &&
            isAfter(new Date(), bookingDate)
          if (booking?.ws_mvp_company_id) {
            due =
              !isSameMonth(bookingDate, new Date()) &&
              !isAfter(bookingDate, new Date())
          }
          return (
            <tr className={due ? "bg-warning" : ""} key={booking?.id}>
              <td
                className={"cursor-pointer"}
                onClick={() => {
                  if (typeof navigator !== undefined) {
                    navigator.clipboard.writeText(booking?.id)
                  }
                }}
              >
                {booking?.id}
              </td>
              <td>{new Date(booking?.created_at).toLocaleString()}</td>
              <td>{(booking?.date || booking.from)?.split("T")?.[0]}</td>
              <td>
                {(booking?.total / 100).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
              <td>{booking?.space?.space_name}</td>
              <td>{booking?.source || "N/A"}</td>
              <td>
                <a
                  href={booking?.pdf_url}
                  target={"_blank"}
                  className={"text-dark"}
                  rel={"noreferrer"}
                >
                  {booking?.user_name || booking?.user_email}
                </a>
              </td>
              <td>{booking?.ws_mvp_company_id ? "Monthly" : "Daily"}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
